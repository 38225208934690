import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../redux/store'; // Adjust the import path to match your actual file structure

interface RootLayoutProps {
  children: React.ReactNode;
}

const RootLayout: React.FC<RootLayoutProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <div style={{ fontFamily: 'Inter, sans-serif' }}>
        {children}
      </div>
    </Provider>
  );
};

export default RootLayout;
