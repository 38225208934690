import { FC } from 'react';
import styles from '../ShabbyShasta/ShabbyShasta.module.css';

interface Props {
  titleText: string;
  descText: string;
  scr?: string;
  alt?: string;
}

const LayoutLeftSection: FC<Props> = (props) => {
  const { titleText, descText, scr, alt } = props;
  return (
    <div
      className={styles.shabbyShastaContainer}
      style={{ marginBottom: '5%' }}
    >
      <div className={styles.imageWrapper}>
        <img src={scr} alt={alt} className={styles.image} />
      </div>
      <div className={styles.textSection}>
        <div className={styles.textContainer}>
          <div className={styles.rightTitle}>{titleText}</div>
          <div className={styles.rightText}>{descText}</div>
        </div>
      </div>
    </div>
  );
};

export default LayoutLeftSection;
