import { FC, lazy } from 'react';
import styles from './HomePage.module.css';
import ResponsiveImages from '../components/Reponsive-images/ReponsiveImages';

const Hero = lazy(() => import('../components/Hero/Hero'));
const ImagesSection = lazy(
  () => import('../components/ImagesSection/ImagesSection')
);
const ShabbyShastaHomepageSection = lazy(
  () => import('../components/ShabbyShasta/ShabbyShasta')
);
const WomanAndDogsSection = lazy(
  () => import('../components/PoochBeach/PoochBeach')
);
const BoyBeachToySection = lazy(
  () => import('../components/BoyBeachToySection/BoyBeachToySection')
);
const LastHomepageSection = lazy(
  () => import('../components/LastHomepageSection/LastHomepageSection')
);

export const altImages =
  'The Hideaway Retreat, navarre rv park, Navarre campground, campground near me, rv park near me';

const HomePageMain: FC = () => {
  return (
    <>
      <Hero />
      <div className={styles.sectionWithImage}>
        <div className={styles.textWrapper}>
          <div style={{marginTop: "3%", marginBottom: 40}}>
            <h1 className={styles.topSectionTitle}>Waterfront Campground</h1>
            <h2 className={styles.lowerSectionTitle}>Navarre, FL</h2>
          </div>
          <p className={styles.sectionText} style={{marginBottom: "3%"}}>
            The Hideaway Retreat was created with purpose and intention, to
            bring about connectivity. Nestled in the beautiful natural setting
            of the Santa Rosa Sound in Navarre, FL, The Hideaway Retreat is a
            boutique vintage waterfront campground with only 50 RV sites, 10
            tent sites, and a waterfront cottage with incredibly stunning views.
          </p>
        </div>
      </div>
      <ImagesSection />
      <div className={styles.section}>
        <p className={styles.sectionText}>
          At The Hideaway, we believe in getting back to basics, to nature, and
          one another the old-fashioned way… in-person.
        </p>
      </div>
      <ResponsiveImages
        altLeft='Lights hang in the oak trees and shine at dusk above the firepit with outdoor games and tree swings while you’re tent and RV camping in Navarre Florida near Pensacola Beach Florida'
        leftImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/home-sec-three-left.jpg'
        altRight='Friends enjoying outdoor camping in their vintage camper at their RV site underneath oak and magnolia shade trees at our hidden gem and waterfront campground, The Hideaway Retreat '
        rightImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/3.JPG'
      />
      <div className={styles.section}>
        <h2 className={styles.sevenFortyTwoTitle} style={{marginBottom: 0}}>
          742 <span className={styles.sevenFortyTwoTitleSmallerText}>ft</span>{' '}
          Beachfront
        </h2>
        <p className={styles.sectionText}>
          With 742 ft of exclusive beachfront, our unique double-tiered beach
          layout makes The Hideaway Retreat a rare find. Situated on a 16 bluff
          that slopes down to our 371 ft Vista Seawall then steps down to
          another 371 ft of private beach below, Hidden Beach, makes for a
          special beach setting. With its simplicity and charm, our waterfront
          RV park is truly a one of a kind camping experience.
        </p>
      </div>
      <ResponsiveImages
        objectPositionLeft='50% 80%'
        altLeft='Lights hang in the oak trees and shine at dusk above the firepit with outdoor games and tree swings while you’re tent and RV camping in Navarre Florida near Pensacola Beach Florida'
        leftImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/home-sec-one-rep-right.jpeg'
        altRight='Cotton candy clouds at dusk over the placid waters of the most relaxing place, Navarre Beach, Florida while RV camping at your incredible waterfront RV sites in Navarre FL on the beautiful Emerald Coast.'
        rightImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/home-sec-three-right.jpg'
      />
      <div className={styles.section}>
        <p className={styles.sectionText}>
          At the end of the day, your feet should be sandy, your hair dirty and
          a smile on your face.
        </p>
      </div>
      <ResponsiveImages
        altLeft='Families enjoying our white beach sand at The Hideaway Retreat’s, Hidden Beach, while playing outdoor games and watching the sun set in the most relaxing place, Navarre Beach Florida '
        leftImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/home-sec-fore-left.jpg'
        altRight='Beautiful, curly-headed blond girl enjoying the swing hung from an old oak above the white gravel common area, the Hangout, while camping at our RV Park in Navarre Florida '
        rightImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/home-sec-fore-right.jpg'
        objectPositionLeft='50% 95%'
      />
      <div className={styles.section}>
        <h2 className={styles.rvTentTitle}>Tent & RV Sites</h2>
        <p className={styles.sectionTextSpecial}>
          With white gravel roads and RV sites, mature oak, and magnolia trees.
          The Hideaway creates an old-fashioned tent and Waterfront RV camping
          experience.
        </p>
      </div>
      <ShabbyShastaHomepageSection />
      <WomanAndDogsSection />
      <BoyBeachToySection />
      {/* TODO: Missing alts */}
      <LastHomepageSection />
    </>
  );
};

export default HomePageMain;
