import * as React from "react";
import { FC } from 'react';
import stylesExp from './ExperiencePage.module.css';
import styles from './PictureBookPage.module.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


const images = [
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/IMG_1704.jpeg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/IMG_5217.jpeg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-1.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-2.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-3.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-5.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-6.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-8.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-9.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-10.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-11.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-14.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-15.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-16.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-17.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-18.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-19.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-20.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnamed-21.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/234t2.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/23rtgsdf.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/323rdfsdf.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/3r52gfsdgf.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/cvdvdd.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/dad4t44tdadagaafg.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/dadfaead.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/dfa33r3.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/dfvadvadgd.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/dsdvd.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/eevvd.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/fgtwrthfbs.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/g5673hgh.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/garegtw.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/gfsfargafg.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/htsrtaf.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/i778r7ughd.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/sfdgdsfbsdfbdf.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/sfdvsdfgsdf-2.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/sfgadfbafb.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/sfgsfdt4544-1.jpg",
  "https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/unnaasdfadsfadsfdsmed.jpg",

];

const PictureBookPage: FC = () => {
  const [isOpen, setOpen] = React.useState(false)
  const [imgIndex, setImgIndex] = React.useState(0)

  return (
    <>
      <Lightbox 
        open={isOpen}
        close={() => setOpen(false)}
        slides={images.map((img) => ({src: img}))}
        index={imgIndex}
      />
      <div className={stylesExp.layoutSubPagesSub}>
        <div className={stylesExp.boxTitle}>
          <h1 className={stylesExp.titleExperience}>Picturebook</h1>
        </div>
      </div>
      <div className={styles.pictureBookContainer}>
        <div className={styles.pictureBook}>
          {images.map((img, index) => {
            return (
              <div key={index} className={styles.pictureContainer} onClick={() => {
                setOpen(true)
                setImgIndex(index)
              }}>
                <img src={img} alt='RV Park & Campground Navarre' className={styles.pictureImage}/>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default PictureBookPage;
