import { FC } from 'react';
import styles from './ExperiencePage.module.css';
import stylesLayout from '../components/PoochBeach/PoochBeach.module.css';
import stylesLux from './LuxuriesPage.module.css';
import { altImages } from './HomePageMain';
import ImagesSections from '../components/ImageSections/ImagesSction';
import ResponsiveImages from '../components/Reponsive-images/ReponsiveImages';

const ExperiencePage: FC = () => {
  return (
    <div className={styles.layoutSubPagesSub}>
      <div className={styles.boxTitleForH}>
        <h1 className={styles.titleExperience}>Experience The Hideaway</h1>
        <img
          src={
            'https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/exp.png'
          }
          alt={altImages}
          className={styles.imageExperience}
          property='image'
          loading='eager'
          draggable={false}
        />
      </div>
      <div className={styles.boxTitle}>
        <p className={styles.sectionText}>
          There is a place where camping is simple. Where cascading oak and
          magnolia trees offer shade and create a sense of calm and cozy at your
          campsite. Where your dog enjoys beach time with you while watching the
          sky turn into serene and captivating colors at dusk. Where there is
          joy, love, acceptance, and a peacefulness that rejuvenates and
          reconnects you with the simple things in life. This is the Hideaway
          Experience.
        </p>
      </div>
      <ResponsiveImages
        objectPositionLeft='50% 80%'
        objectPositionRight='50% 80%'
        rightImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/lux-sec-one-right.png'
        leftImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/lux-sec-one-left.png'
      />
      <div className={stylesLayout.poochBeachContainer}>
        <div className={stylesLayout.textSection}>
          <div className={stylesLayout.textContainer}>
            <div className={stylesLux.subTitleText}>
              I am most alive among the tall trees
            </div>
          </div>
        </div>
        <div className={stylesLayout.imageWrapper}>
          <img
            src={
              'https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/exp-tree.png'
            }
            alt={altImages}
            className={styles.imageTree}
            loading='eager'
            draggable={false}
            decoding='async'
          />
        </div>
      </div>
      <ResponsiveImages
        objectPositionLeft='50% 90%'
        objectPositionRight='50% 80%'
        leftImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/exp-sec-two-left.png'
        rightImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/unnamed-4.jpg'
      />
      <div className={styles.boxTitle}>
        <p className={styles.sectionText}>
          There is an undeniable vibe you will feel while camping at The
          Hideaway Retreat. It’s one of inclusion and friendliness with a sense
          of harmony and stillness that will leave you wanting more.
        </p>
      </div>
      <ResponsiveImages
        objectPositionLeft='50% 65%'
        objectPositionRight='50% 80%'
        rightImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/unnamed-2.jpg'
        leftImage='https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/picturebook/cvdvdd.jpg'
      />
      <div className={styles.boxTitle}>
        <h2 className={stylesLux.subTitleText}>
          A dog’s life…here at The Hideaway
        </h2>
      </div>
      <ImagesSections
        src={
          'https://thehideawayretreat-storage-images234800-staging.s3.amazonaws.com/exp-group-fore.png'
        }
        alt={altImages}
      />
      <div className={styles.boxTitle} style={{marginTop: 20}}>
        <h2 className={stylesLux.subTitleText}>
          A little love note from the owner
        </h2>
        <p className={styles.sectionText}>I wanted a place for people to come to and connect with nature and one another, like in the old days when things were simpler.  When we interacted in person and not online.  When we went out of our way to say “hi” to a complete stranger.  And get to know one another, create memories, and experience the true meaning of life…relationships.  A special and magical place where you felt loved and accepted like you're visiting an old friend.  To relax and forget about the daily stresses of life.  To live a little and return feeling refreshed.  I’ve poured my heart and soul into creating a cozy, homy, and peaceful place for you to do just that.  I hope you like it and make The Hideaway your home away from home.</p>
        <p className={styles.sectionText}>
          Many blessings, Jeanie.
        </p>
      </div>
    </div>
  );
};

export default ExperiencePage;
