import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalName } from '../types';

interface ModalsState {
  isOpen: boolean;
  modals: Record<ModalName, boolean>;
}

const initialState: ModalsState = {
  isOpen: false,
  modals: {
    contactUs: false,
    campMap: false,
    forYourTrip: false,
    luxuries: false,
    attractions: false,
    ownersPortalLogin: false,
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isOpen = !state.isOpen;
    },
    openModal: (state, action: PayloadAction<ModalName>) => {
      state.modals[action.payload] = true;
      state.isOpen = false;
    },
    closeModal: (state, action: PayloadAction<ModalName>) => {
      state.modals[action.payload] = false;
    },
  },
});

export const { toggleMenu, openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
